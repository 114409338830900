<template>
  <div style="margin: 10px; width: calc(100vw - 85px)">
    <el-dialog :visible.sync="isOpenTemporaryLinkForm" :title="isNewTemporaryLink?$t('settings.temporary_link_add'):$t('settings.temporary_link_edit')">
      <el-form :model="temporaryLinkForm">
        <el-form-item :label="$t('settings.user_name')">
          <el-input v-model="temporaryLinkForm.name" />
        </el-form-item>
        <div class="form-item-block">
          <div class="form-item-row">
            <el-form-item class="form-item-block-left" :label="$t('settings.expiration_date')">
              <el-date-picker
                v-model="temporaryLinkForm.expirationTime"
                type="datetime"
                :placeholder="$t('Seleccionar fecha')"
                style="width: 100%"
                default-time="23:59:59"
              ></el-date-picker>
            </el-form-item>
            <el-form-item class="form-item-block-right" :label="$t('Destination Address')">
              <el-input
                id="input"
                v-model="searchAddress"
                type="search"
                style="width: 100%"
              >
              </el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item style="padding-top: 20px">
          <el-transfer
            v-model="temporaryLinkForm.linkSelectedDevices"
            :filter-method="filteredDevices"
            filterable
            :filter-placeholder="$t('report.selector_search')"
            :titles="[$t('settings.vehicles'), $t('report.select_vehicles')]"
            :props="{
              key: 'id',
              label: 'name'
            }"
            :data="devices"
            :render-content="renderFunc"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          class="formButton"
          size="small"
          @click="handleCancelForm"
        >{{ $t('settings.form_cancel') }}</el-button>
        <el-button
          :loading="loading"
          type="success"
          class="formButton"
          size="small"
          @click="handleSubmitForm"
        >{{ $t('settings.form_save') }}</el-button>
      </span>
    </el-dialog>
    <el-table
      height="calc(100vh - 100px)"
      :row-style="tableRowStyle"
      :header-cell-style="tableHeaderStyle"
      :data="filteredUsers"
    >
      <el-table-column
        :label="$t('settings.user_name')"
        width="300px"
        prop="name"
        sortable
      >
      </el-table-column>
      <el-table-column
        width="175px"
        :label="$t('settings.expiration_date')"
        sortable
      >
        <template slot-scope="scope">
          <span>{{ scope.row.expirationTime | moment("YYYY-MM-DD HH:mm:ss") }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('settings.user_temporary_link')">
        <template slot-scope="scope">
          <a :href="externalLink(scope.row)" target="_blank" style="padding-right: 10px">{{ externalLink(scope.row) }}</a>
          <el-tooltip :content="$t('Copy Link')" placement="top">
            <el-button type="small" @click="copy(externalLink(scope.row))"><i class="fas fa-clone"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="" width="80px">
        <template slot="header">
          <div style="float: right">
            <el-tooltip :content="$t('settings.add')" placement="top">
              <el-button
                class="formButton"
                size="small"
                @click="handleAdd"
              ><i class="fas fa-plus"></i></el-button>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <el-tooltip :content="$t('settings.delete')" placement="top">
            <el-button
              class="formButton"
              size="small"
              type="danger"
              @click="handleDelete(scope.row)"
            ><i class="fas fa-trash-alt"></i></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total"
      :total="filteredUsers.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { vm } from '@/main'
import { mapGetters } from 'vuex'
import { traccar } from '@/api/traccar-api'
import Vue from 'vue'
import { Loader } from '@googlemaps/js-api-loader'

export default {
  name: 'TemporaryLinks',
  data() {
    return {
      searchAddress: '',
      isOpenTemporaryLinkForm: false,
      isNewTemporaryLink: true,
      selectedTemporaryLink: null,
      search: '',
      loading: false,
      temporaryLinkForm: {
        poi: null,
        name: '',
        expirationTime: '',
        linkDevices: [],
        linkSelectedDevices: []
      },
      filteredDevices(query, item) {
        return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'users', 'devices', 'geofences']),
    filteredUsers() {
      return this.users.filter(u => u.email.startsWith('temp_')).filter(data => (!this.search ||
        data.name.toLowerCase().includes(this.search.toLowerCase()) ||
        data.email.toLowerCase().includes(this.search.toLowerCase())))
    }
  },
  watch: {
    isOpenTemporaryLinkForm(value) {
      if (value && !this.autoComplete) {
        new Loader({
          apiKey: process.env.googleKey, libraries: ['places']
        }).load().then(() => {
          // eslint-disable-next-line no-undef
          this.autoComplete = new google.maps.places.Autocomplete(document.getElementById('input'))
          this.autoComplete.addListener('place_changed', this.onPlaceChanged)
        })
      }
    }
  },
  methods: {
    onPlaceChanged() {
      const place = this.autoComplete.getPlace()
      if (place.geometry) {
        this.temporaryLinkForm.poi = `${place.geometry.location.lng()},${place.geometry.location.lat()}`
        this.searchAddress = place.formatted_address
      }
    },
    copy(link) {
      navigator.clipboard.writeText(link)
      this.$notify({
        title: 'Copiado',
        message: 'Copiado para a área de transferência (clipboard).',
        type: 'success'
      })
    },
    randomString() {
      return Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2)
    },
    externalLink(user) {
      return `https://eta.fleetmap.io/?token=${user.token}`
    },
    tableRowStyle() {
      return 'font-size: 14px'
    },
    tableHeaderStyle() {
      return 'font-size: 14px'
    },
    async handleEdit(row) {

    },
    handleAdd() {
      this.isNewTemporaryLink = true
      this.selectedTemporaryLink = null
      this.temporaryLinkForm.name = ''
      this.temporaryLinkForm.expirationTime = ''
      this.temporaryLinkForm.linkDevices = []
      this.temporaryLinkForm.linkSelectedDevices = []
      this.temporaryLinkForm.poi = null
      this.searchAddress = ''
      this.isOpenTemporaryLinkForm = !this.isOpenTemporaryLinkForm
    },
    handleDelete(row) {
      this.$confirm(this.$t('settings.temporary_link_delete_info') + row.name, this.$t('settings.temporary_link_delete_title'), {
        confirmButtonText: this.$t('settings.form_confirm'),
        cancelButtonText: this.$t('settings.form_cancel')
      }).then(() => {
        traccar.deleteUser(row.id, this.userDeleted)
      }).catch((error) => {
        this.$log.error(error)
      })
    },
    userDeleted(id) {
      this.$log.debug('temporary link deleted')
      this.$message({
        message: this.$t('settings.temporary_link_deleted'),
        type: 'success',
        duration: 5 * 1000
      })
      const user = vm.$store.state.user.users.find(u => u.id === id)
      this.$store.dispatch('user/removeUser', user)
    },
    handleCancelForm() {
      this.isOpenTemporaryLinkForm = false
    },
    handleSubmitForm() {
      if (this.temporaryLinkForm.linkSelectedDevices.length) {
        if (new Date(this.temporaryLinkForm.expirationTime).getTime() > Date.now()) {
          this.loading = true
          const token = this.randomString()
          const newUser = {
            name: this.temporaryLinkForm.name,
            email: 'temp_' + token,
            readonly: true,
            administrator: false,
            password: token,
            token: token,
            expirationTime: this.temporaryLinkForm.expirationTime,
            attributes: {
              'ui.disableReport': true,
              'ui.disableEvents': true,
              'ui.disableVehicleFeatures': true,
              linkVersion: this.temporaryLinkForm.poi,
              endAddress: this.searchAddress
            }
          }
          traccar.addUser(newUser)
            .then(response => this.userCreated(response.data))
            .catch(reason => {
              this.loading = false
              if (reason.response.data.startsWith('Duplicate entry')) {
                this.$message({
                  message: this.$t('settings.user_duplicated_entry'),
                  type: 'warning',
                  duration: 5 * 1000
                })
              } else if (reason.response.data.startsWith('Manager access required') ||
                reason.response.data.startsWith('Account is readonly')) {
                this.$message({
                  message: this.$t('settings.user_create_not_allowed'),
                  type: 'warning',
                  duration: 5 * 1000
                })
              } else {
                Vue.$log.error(reason)
              }
            })
        } else {
          this.$alert(this.$t('A data limite não pode ser anterior à data actual'))
        }
      } else {
        this.$alert(this.$t('Seleccione um veículo'))
      }
    },
    userCreated: async function(newUser) {
      try {
        await this.$store.dispatch('user/addUser', newUser)

        await this.updateUserPermissions(newUser)

        this.$message({
          type: 'success',
          message: this.$t('settings.temporary_link_created')
        })
      } catch (e) {
        Vue.$log.error(e)
      } finally {
        this.isOpenTemporaryLinkForm = false
        this.loading = false
      }
    },
    async updateUserPermissions(user) {
      const devicePermissionsToAdd = this.temporaryLinkForm.linkSelectedDevices.map(d => {
        return {
          userId: user.id,
          deviceId: d
        }
      })
      await traccar.addAllPermissions(devicePermissionsToAdd)
    },
    renderFunc(h, option) {
      return <span title={option.name}>{option.name}</span>
    },
    doNothing(scope) {
      /* this method is here because we need the attribute 'slot-scope = "scope"' on the template
       for search box to work, but to be able to commit the variable 'scope' it must be used*/
    }
  }
}
</script>
<style lang="scss">
@import '../../../styles/element-variables.scss';
a {
  color: $--color-primary !important;
}

.dialog__wrapper {
 z-index: 10 !important;
}

.pac-container {
  z-index: 3000 !important;
}

</style>
